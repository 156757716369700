.App {
  text-align: center;
  background: #F5F5F5;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@import url('https://fonts.cdnfonts.com/css/mulish');

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.measurementLabel {
  background: black;
  padding: 4px 10px;
  border-radius: 20px;
  color: white
}

.textIcon {
  font-size: 20px;
  margin-right: 10px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Auth-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.Auth-form-popup {
  width: 100vw;
  height: 100vh;
}

.e-toast-container .e-toast {
  background-color: navy;
}

.Auth-form {
  width: 420px;
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  padding-top: 30px;
  padding-bottom: 20px;
  border-radius: 8px;
  background-color: white;
}

.logo-up {
  text-align: center;
  margin-bottom: 32px;
}

.Auth-form-content {
  padding-left: 12%;
  padding-right: 12%;
  overflow-x: auto;
}

.Auth-form-title {
  text-align: center;
  margin-bottom: 1em;
  font-size: 24px;
  color: rgb(34, 34, 34);
  font-weight: 660;
}

label {
  font-size: 14px;
  font-weight: 600;
  color: rgb(34, 34, 34);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.react-draggable {
  min-height: 180px;
  min-width: 200px;
}

.react-draggable.react-draggable-dragged {
  min-height: 180px;
  min-width: 200px;
}

.cube-scene {
  position: absolute;
  top: 60px;
  right: 0px;
  width: 180px;
  height: 240px;
  /* background-color: #FFF; */
}

.MuiDataGrid-columnHeaderTitle {
  font-weight: bold !important;
  overflow: visible !important;
  font-size: 12px;

}

.MuiDataGrid-columnHeaderCheckbox {
  background-color: #E7E7E7;
}

.MuiDataGrid-columnHeader {
  background-color: #F5F5F5;
  min-height: 40px;
  max-height: 40px;
  line-height: 40px;
  color: #404040 !important;
  border-right: 1px solid #C3C3C3 !important;
  /* padding: 0px !important; */
}
.MuiDataGrid-columnHeader-mini {
  background-color: #F5F5F5;
  min-height: 40px;
  max-height: 40px;
  line-height: 40px;
  color: #404040 !important;
  border-right: 1px solid #C3C3C3 !important;
  padding: 0px !important;
}

/* Style the DataGrid header container */
/*  */

.MuiDataGrid-columnHeadersInner {
  border: 1px solid #C3C3C3;
}

.tilt-grid-cell {
  font-size: 12px;
  border-right: 1px solid #C3C3C3 !important;
  border-top: 1px solid #C3C3C3 !important;
}

.MuiDataGrid-cell {
  min-height: 40px;
  max-height: 40px;
  line-height: 40px;
  color: #404040 !important;
  /* border-right: 1px solid #C3C3C3 !important; */
  border-top: 1px solid #C3C3C3 !important;
  /* padding: 0px !important; */
}
.MuiDataGrid-cell-mini {
  min-height: 40px;
  max-height: 40px;
  line-height: 40px;
  color: #404040 !important;
  /* border-right: 1px solid #C3C3C3 !important; */
  border-top: 1px solid #C3C3C3 !important;
  padding: 0px !important;
}

/* Remove the right border from the last column header */
/* .MuiDataGrid-cell:last-child {
  border-right: none !important;
} */
.MuiDataGrid-root .MuiDataGrid-row:last-child .MuiDataGrid-cell {
  border-bottom: 1px solid #C3C3C3 !important;
}

.MuiDataGrid-cell:first-child {
  border-left: 1px solid #C3C3C3 !important;
}

.leaflet-control-zoom-in{
  border-top-left-radius: 6px !important;
  border-top-right-radius: 6px !important;
  border: 1px solid #c3c3c3;
  border-bottom: none !important;
  box-shadow: (0 0 0 / 20%) 0px 4px 4px !important;
}
.leaflet-control-zoom-out{
  border-bottom-left-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
  border: 1px solid #c3c3c3;
  
}
.leaflet-control-zoom{
  border: none !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2) !important;
  
}
.leaflet-control-zoom-in{
  color:#777777 !important;
}
.leaflet-control-zoom-out{
  color:#777777 !important;
}
.error-div:hover{
  background-color: #f5f5f5;
}
.error-div{
  background-color: none;
  padding: 2px 10px;
}
.number-forefront:hover{
  color:#404040 !important;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}