body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
  padding: 0;
}

/* input[type="text"] {
 padding:0;
} */
.cell-input {
  padding: 0;
}

.text-field {
  width: 100%;
}

/* html, body, #app, #app>div {
  height: 100%
} */

body>#root>div {
  height: 100%;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container-projects {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

::-webkit-scrollbar {
  width: 7px; /* set to a smaller value */
  height: 7px;
}
::-webkit-scrollbar-thumb {
  background-color: #0086CA;
  border-radius: 10px;
}


::-webkit-scrollbar-track {
  background-color: #B1E5FF;
  border-radius: 10px;
}


.rotate {
  transform: rotate(180deg); 
  transform-origin: left top
}