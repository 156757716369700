.ide {
    background: #F5F5F5;
    text-align: start;
    font-size: 16px;
    color: #404040;
    user-select: none;
    padding: 12px;
    border-radius: 0.4em;
    margin: 20px;
    overflow: auto;
    height: calc(100% - 120px);
    min-width: 350px;
}

.ide .tree,
.ide .tree-node,
.ide .tree-node-group {
    list-style: none;
    margin: 0;
    padding: 1px;
    line-height: 32px;

}

.ide .tree-branch-wrapper,
.ide .tree-node__leaf {
    outline: none;
    outline: none;
}

.ide .tree-node {
    cursor: pointer;
}

.ide .tree-node:hover {
    background: rgba(255, 255, 255, 0.1);
}

.ide .tree .tree-node--focused {
    background: rgba(255, 255, 255, 0.2);
}

.ide .tree .tree-node--selected {
    background: rgba(0, 134, 202, 0.7);
    color: white;
}

.ide .tree-node__branch {
    display: block;
}

.ide .icon {
    vertical-align: middle;
    margin-right: 5px;
}