 .container-table {
    display: block;
    justify-content: left;
    align-items: left;
    overflow: auto;
    height: calc(100vh - 100px);
 }
 
 /* Tables */
 .tableEtcs{
    width:100%;
    margin-bottom:1em;
    border-collapse: collapse;
}
.thEtcs{
    font-weight:bold;
    background-color:#ddd;
    position: sticky;
    top: -1px;
    min-width: 100px;
}
.thEtcs100{
    font-weight:bold;
    background-color:#fff2cc;
    position: sticky;
    top: -1px;
    min-width: 100px;
    border-right: 1px solid #000;
}
.thEtcs130{
    font-weight:bold;
    background-color:#fce4d6;
    position: sticky;
    top: -1px;
    min-width: 100px;
    border-right: 1px solid #000;
}
.thEtcsK{
    font-weight:bold;
    background-color:#e2efda;
    position: sticky;
    top: -1px;
    min-width: 100px;
    border-right: 1px solid #000;
}
.thEtcs150{
    font-weight:bold;
    background-color:#f7cccc;
    position: sticky;
    top: -1px;
    min-width: 100px;
    border-right: 1px solid #000;
}

.thEtcsEmpty{
    font-weight:bold;
    background-color:#ddd;
    position: sticky;
    top: -1px;
    min-width: 1px;
}
.freeze {
  position: sticky;
  left: 0;
  background-color: white;
  z-index: 999999;
}
.thEtcs,
.tdEtcs{
    padding:0.5em;
    border:1px solid #ccc;
}