.user-button {
    width: 32px;
    height: 32px;
    background: #0086CA;
    cursor: pointer;
    border: 4px solid #C3C3C3;
    color: white;
    border-radius: 40px;
    line-height: 32px;
    margin-left: 16px;
    margin-top: 8px;
    font-size: 16px;
    transition: background-color 0.7s ease; 
}
.user-button:hover {
    background-color: #0072a3; /* Change the background color on hover */
    border: 4px solid #A2A2A2;
}

.user-button:active {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5); /* Add a box-shadow on click */
    background-color: #00a1f2; /* Change the background color on click */
}