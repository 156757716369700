.container {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    overflow:hidden;
}

.centered-div {
    width: 25%;
    overflow: auto;
    min-width: 260px;
    padding: 30px;
    background: white;
    position: absolute;
    top:25%;
    
    color: #404040;
    min-height: 400px;
    border-radius: 4px;
    font-family: 'Mulish';

}